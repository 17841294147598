import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import RaeShopItem from "../components/RaeShopItem";

import "bootstrap/dist/css/bootstrap.min.css";
import "./RaeCollection.css";

function RaeCollection({
  raeCollectionItems,
  handleAddToCart,
  setNavMenuActive,
  setCartModalActive,
  setShopItemModalActive,
  shopItemModalActive,
}) {
  const itemFilters = [
    "alles",
    "oorbellen",
    "ketting",
    "armband",
    "ring",
    "cadeaubon",
    "rae's rescue plan",
  ];
  const materialFilters = ["zie prijzen"];

  const [activeItemFilter, setActiveItemFilter] = useState(null);
  const [activeMaterialFilter, setActiveMaterialFilter] = useState(false);
  const navigate = useNavigate();

  const filteredItems = raeCollectionItems.filter((item) => {
    if (activeItemFilter === null || activeItemFilter === "alles") {
      return true;
    }

    if (activeItemFilter === "rae's rescue plan") {
      return item.beschikbaar === true;
    }

    return item.soort === activeItemFilter;
  });

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash === "#raeCollection") {
      const element = document.getElementById("raeCollection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div className='RaeCollection' id='raeCollection'>
      <h1 className='RaeCollection-title'>
        onze <span className='RaeCollection-title-bold'>collectie</span>
      </h1>
      <div className='RaeCollection-title-filterItemRow'>
        <FilterItems
          items={itemFilters}
          baseId='RaeCollection-title-filterItemRow-item'
          activeFilter={activeItemFilter}
          onClick={setActiveItemFilter}
        />
      </div>
      {activeItemFilter === "rae's rescue plan" && (
        <p className='RaeCollection-rescue-intro'>
          Deze zorgvuldig geselecteerde juweeltjes zijn meteen beschikbaar. Geen
          wachttijd, geen stress ;)
        </p>
      )}
      <div className='RaeCollection-itemContainer'>
        {filteredItems.map((item) => (
          <RaeShopItem
            key={item.itemId}
            shopItem={item}
            setNavMenuActive={setNavMenuActive}
            setCartModalActive={setCartModalActive}
            handleAddToCart={handleAddToCart}
            setShopItemModalActive={setShopItemModalActive}
            shopItemModalActive={shopItemModalActive}
          />
        ))}
      </div>
    </div>
  );
}

function FilterItems({ items, baseId, activeFilter, onClick }) {
  const regularFilters = items.filter((item) => item !== "rae's rescue plan");
  const rescueFilter = items.find((item) => item === "rae's rescue plan");

  return (
    <>
      <div className={`${baseId}-filters desktop`}>
        <div className='filters-container'>
          <div className='regular-filters'>
            {regularFilters.map((item) => (
              <div
                key={item}
                id={`${baseId}-filters-${item}`}
                className={`filter-item ${
                  item === activeFilter ? "active-filter" : ""
                }`}
                onClick={() => onClick(item)}
              >
                {item}
              </div>
            ))}
          </div>
          <div
            key={rescueFilter}
            id={`${baseId}-filters-${rescueFilter}`}
            className={`filter-item rescue-plan ${
              rescueFilter === activeFilter ? "active-filter" : ""
            }`}
            onClick={() => onClick(rescueFilter)}
          >
            {rescueFilter}
          </div>
        </div>
      </div>
      <Form.Select
        className={`${baseId}-filters mobile`}
        value={activeFilter || ""}
        onChange={(e) => onClick(e.target.value)}
      >
        {items.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </Form.Select>
    </>
  );
}

export default RaeCollection;
